@mixin media-tablet {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: 375px) {
    @content;
  }
}
