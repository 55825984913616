.slides {
  position: relative;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}

.slides--hidden {
  opacity: 0;
}

.slides__bg {
  height: 100%;
  user-select: none;
  transition: var(--bg-transition);
}

.slides__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--padding-bottom) calc(var(--padding) + 2rem);
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);

  @include media-tablet {
    padding: var(--padding-bottom--mobile) calc(var(--padding) + 1rem);
  }

  @include media-mobile {
    padding: var(--padding-bottom--mobile) var(--padding);
  }
}

.slides__item--shown {
  opacity: 1;
  visibility: visible;
}

.slides__heading {
  max-width: 27rem;
  // position: absolute;
}

.slides__item--top-left {
  padding: var(--padding) var(--padding) var(--padding-bottom);
  justify-content: space-between;

  @include media-tablet {
    padding-bottom: var(--padding-bottom--mobile);
  }
}

.slides__item--center-left {
  justify-content: center;
}

.slides__item--center-right {
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}

// .slides__heading--top-left {
//   top: var(--padding);
//   left: var(--padding);
//   padding-right: var(--padding);
// }

// .slides__heading--center-left {
//   top: calc(50% - 3.2rem);
//   left: calc(var(--padding) + 2rem);
//   padding-right: var(--padding);

//   @include media-tablet {
//     top: calc(45% - 3.2rem);
//   }

//   @include media-mobile {
//     left: var(--padding);
//   }
// }

// .slides__heading--center-right {
//   top: calc(50% - 3.2rem);
//   text-align: right;
//   right: calc(var(--padding) + 2rem);
//   padding-left: var(--padding);

//   @include media-tablet {
//     top: calc(45% - 3.2rem);
//   }

//   @include media-mobile {
//     right: var(--padding);
//   }
// }

// .slides__heading--center-right-shifted {
//   top: calc(50% - 6.4rem);
//   text-align: right;
//   max-width: 20rem;
//   right: calc(var(--padding) + 2rem);
//   padding-left: var(--padding);

//   @include media-tablet {
//     top: calc(45% - 6.4rem);
//   }

//   @include media-mobile {
//     right: var(--padding);
//   }
// }
