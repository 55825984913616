/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@font-face {
  font-family: "Klavika";
  src: url("Klavika-Bold.c0677fdf.eot");
  src: local("Klavika-Bold"), url("Klavika-Bold.c0677fdf.eot#iefix") format("embedded-opentype"), url("Klavika-Bold.56311df3.woff2") format("woff2"), url("Klavika-Bold.2948416c.woff") format("woff"), url("Klavika-Bold.10c068ec.ttf") format("truetype"), url("Klavika-Bold.746909ea.svg#Klavika-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Klavika";
  src: url("Klavika-Regular.2c5560af.eot");
  src: local("Klavika Rg"), local("Klavika-Regular"), url("Klavika-Regular.2c5560af.eot#iefix") format("embedded-opentype"), url("Klavika-Regular.1d38d49a.woff2") format("woff2"), url("Klavika-Regular.2ee54b07.woff") format("woff"), url("Klavika-Regular.a2a812e5.ttf") format("truetype"), url("Klavika-Regular.f84003b5.svg#Klavika-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Klavika";
  src: url("Klavika-Light.b7627133.eot");
  src: local("Klavika Lt"), local("Klavika-Light"), url("Klavika-Light.b7627133.eot#iefix") format("embedded-opentype"), url("Klavika-Light.19e6ecab.woff2") format("woff2"), url("Klavika-Light.5799f556.woff") format("woff"), url("Klavika-Light.24b01efe.ttf") format("truetype"), url("Klavika-Light.7df04b9f.svg#Klavika-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Klavika";
  src: url("Klavika-Medium.f3c254fb.eot");
  src: local("Klavika Md Bold"), local("Klavika-Medium"), url("Klavika-Medium.f3c254fb.eot#iefix") format("embedded-opentype"), url("Klavika-Medium.379e7973.woff2") format("woff2"), url("Klavika-Medium.ca2c8e29.woff") format("woff"), url("Klavika-Medium.03844d96.ttf") format("truetype"), url("Klavika-Medium.3f5ca2dc.svg#Klavika-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
:root {
  --padding: 2rem;
  --padding-bottom: 9rem;
  --padding-bottom--mobile: 30vh;
  --font: "Klavika", sans-serif;
  --transition-duration: 300ms;
  --transition: all var(--transition-duration) ease-in-out;
  --loading-transition: all 1s linear;
  --bg-transition: all 0.5s linear;
  --color-smoke: #ffffffee;
  --color-primary: #fff;
  --color-secondary: #000;
  --color-active: yellow;
  --color-selection: #00000070;
}
* {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  font-family: var(--font);
  font-size: 16px;
  color: var(--color-primary);
  background: var(--color-secondary);
  line-height: 1.2;
  overflow: hidden;
}
h1 {
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
  font-size: 3rem;
}
@media (max-width: 960px) {
  h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 375px) {
  h1 {
    font-size: 2rem;
  }
}
p {
  text-shadow: 0px 0px 4px var(--color-secondary);
}
button {
  font-family: var(--font);
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  transition: var(--transition);
}
.main--hidden {
  opacity: 0;
}
::selection {
  background: var(--color-selection);
  color: var(--color-primary);
}
.hideable {
  transition: var(--transition);
}
.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}
.shown {
  opacity: 1 !important;
  visibility: visible !important;
}
a {
  color: var(--color-primary);
  text-decoration: none;
}
.loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--loading-transition);
}
.loading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-style: italic;
  animation: loadingContent var(--transition-duration) cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.loading__content::before {
  content: "";
  position: absolute;
  box-shadow: -2.5px -15px 80px 35px var(--color-smoke);
}
.loading__img {
  user-select: none;
  position: relative;
  z-index: 1;
  animation: loadingImg infinite 3s var(--transition-duration) ease-in-out;
}
.loading__line {
  display: flex;
  height: 32px;
  transition: var(--transition);
}
.loading__title {
  opacity: 0;
  transform: scale(0);
  display: block;
  transition: var(--transition);
}
.loading__title--shown {
  opacity: 1;
  transform: scale(1);
}
.loading__suffix {
  opacity: 1;
}
.loading__suffix--hidden {
  opacity: 0;
  width: 0;
}
.loading__message {
  opacity: 0;
  width: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  transition: var(--transition);
}
.loading__message--shown {
  opacity: 1;
  width: 122px;
  left: -4px;
}
@keyframes loadingContent {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes loadingImg {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
.slides {
  position: relative;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}
.slides--hidden {
  opacity: 0;
}
.slides__bg {
  height: 100%;
  user-select: none;
  transition: var(--bg-transition);
}
.slides__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--padding-bottom) calc(var(--padding) + 2rem);
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}
@media (max-width: 960px) {
  .slides__item {
    padding: var(--padding-bottom--mobile) calc(var(--padding) + 1rem);
  }
}
@media (max-width: 375px) {
  .slides__item {
    padding: var(--padding-bottom--mobile) var(--padding);
  }
}
.slides__item--shown {
  opacity: 1;
  visibility: visible;
}
.slides__heading {
  max-width: 27rem;
}
.slides__item--top-left {
  padding: var(--padding) var(--padding) var(--padding-bottom);
  justify-content: space-between;
}
@media (max-width: 960px) {
  .slides__item--top-left {
    padding-bottom: var(--padding-bottom--mobile);
  }
}
.slides__item--center-left {
  justify-content: center;
}
.slides__item--center-right {
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}
footer {
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 var(--padding);
  display: flex;
  gap: 2rem;
  align-items: flex-end;
  height: var(--padding-bottom);
}
@media (max-width: 960px) {
  footer {
    height: var(--padding-bottom--mobile);
    align-items: flex-start;
    padding-top: 2rem;
    gap: 1rem;
    flex-direction: column;
  }
}
.copyright {
  margin-left: auto;
  align-self: flex-end;
}
.copyright svg {
  width: 5rem;
  height: 5rem;
  fill: var(--color-primary);
}
@media (max-width: 960px) {
  .copyright {
    position: absolute;
    bottom: 0;
    right: var(--padding);
  }
}
.arrow {
  position: absolute;
  top: calc(50% - 2rem);
  border-style: solid;
  border-color: var(--color-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 1rem;
  transition: var(--transition);
  opacity: 1;
  visibility: visible;
  z-index: 5;
}
@media (max-width: 960px) {
  .arrow {
    padding: 0.6rem;
  }
}
@media (max-width: 375px) {
  .arrow {
    padding: 0.5rem;
  }
}
.arrow--prev {
  transform: rotate(135deg);
  left: calc(0% + 1.5rem);
}
@media (max-width: 960px) {
  .arrow--prev {
    left: calc(0% + 1rem);
  }
}
@media (max-width: 375px) {
  .arrow--prev {
    left: calc(0% + 0.75rem);
  }
}
.arrow--next {
  transform: rotate(-45deg);
  right: calc(0% + 1.5rem);
}
@media (max-width: 960px) {
  .arrow--next {
    right: calc(0% + 1rem);
  }
}
@media (max-width: 375px) {
  .arrow--next {
    right: calc(0% + 0.75rem);
  }
}
.step {
  height: 30px;
  line-height: 30px;
  margin-bottom: 2rem;
  transition: var(--transition);
}
@media (max-width: 960px) {
  .step {
    line-height: 1.3;
    padding-right: 5rem;
  }
}
nav {
  height: 30px;
  display: flex;
  border-width: 4px;
  border-style: dotted;
  padding: 3px 0;
  border-color: var(--color-primary);
  margin-bottom: 2rem;
}
@media (max-width: 960px) {
  nav {
    margin-bottom: 0rem;
  }
}
.control {
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 16px;
  border-width: 0 4px 0 0;
  border-style: dotted;
  border-color: var(--color-primary);
  position: relative;
}
.control::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 0px;
  bottom: 0px;
  right: 3px;
  transition: var(--transition);
  background: transparent;
}
.control:last-child {
  border-width: 0;
}
.control.active::before {
  background: var(--color-primary);
}
@media (max-width: 375px) {
  .control {
    width: 25px;
  }
}
aside {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80vw;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: var(--padding);
  padding-bottom: var(--padding-bottom);
  z-index: 0;
  transform: translateX(100%);
  transition: var(--transition);
}
aside p a {
  color: var(--color-primary);
  text-decoration: underline;
  transition: var(--transition);
}
aside p a:hover {
  color: var(--color-active);
}
@media (max-width: 960px) {
  aside {
    padding-bottom: var(--padding-bottom--mobile);
  }
}
.aside--shown {
  transform: translateX(0);
}
.aside__content {
  margin-top: auto;
  margin-bottom: auto;
}
.social {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}
@media (max-width: 960px) {
  .social {
    flex-direction: column;
    align-items: flex-end;
  }
}
.social__icon svg {
  transition: var(--transition);
  fill: var(--color-primary);
  width: 1rem;
  height: 1rem;
}
.social__item {
  position: relative;
  text-decoration: none;
  display: flex;
  gap: 0.5rem;
  transition: var(--transition);
}
.social__item::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  bottom: -0.5rem;
  left: 0;
  width: 0;
  transition: var(--transition);
  background: var(--color-active);
}
.social__item:hover {
  color: var(--color-active);
}
.social__item:hover .social__icon svg {
  fill: var(--color-active);
}
.social__item:hover::after {
  width: 100%;
}
/*# sourceMappingURL=index.50ea7efb.css.map */
