footer {
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 var(--padding);
  display: flex;
  gap: 2rem;
  align-items: flex-end;
  height: var(--padding-bottom);

  @include media-tablet {
    height: var(--padding-bottom--mobile);
    align-items: flex-start;
    padding-top: 2rem;
    gap: 1rem;
    flex-direction: column;
  }
}

.copyright {
  margin-left: auto;
  align-self: flex-end;

  svg {
    width: 5rem;
    height: 5rem;
    fill: var(--color-primary);
  }

  @include media-tablet {
    position: absolute;
    bottom: 0;
    right: var(--padding);
  }
}

.arrow {
  position: absolute;
  top: calc(50% - 2rem);
  border-style: solid;
  border-color: var(--color-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 1rem;
  transition: var(--transition);
  opacity: 1;
  visibility: visible;
  z-index: 5;

  @include media-tablet {
    padding: 0.6rem;
  }

  @include media-mobile {
    padding: 0.5rem;
  }
}

.arrow--prev {
  transform: rotate(135deg);
  left: calc(0% + 1.5rem);

  @include media-tablet {
    left: calc(0% + 1rem);
  }

  @include media-mobile {
    left: calc(0% + 0.75rem);
  }
}

.arrow--next {
  transform: rotate(-45deg);
  right: calc(0% + 1.5rem);

  @include media-tablet {
    right: calc(0% + 1rem);
  }

  @include media-mobile {
    right: calc(0% + 0.75rem);
  }
}

.step {
  height: 30px;
  line-height: 30px;
  margin-bottom: 2rem;
  transition: var(--transition);

  @include media-tablet {
    line-height: 1.3;
    padding-right: 5rem;
  }
}

nav {
  height: 30px;
  display: flex;
  border-width: 4px;
  border-style: dotted;
  padding: 3px 0;
  border-color: var(--color-primary);
  margin-bottom: 2rem;

  @include media-tablet {
    margin-bottom: 0rem;
  }
}

.control {
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 16px;
  border-width: 0 4px 0 0;
  border-style: dotted;
  border-color: var(--color-primary);
  position: relative;

  &::before {
    content: '';

    position: absolute;
    left: 3px;
    top: 0px;
    bottom: 0px;
    right: 3px;

    transition: var(--transition);
    background: transparent;
  }

  &:last-child {
    border-width: 0;
  }

  &.active {
    &::before {
      background: var(--color-primary);
    }
  }

  @include media-mobile {
    width: 25px;
  }
}
