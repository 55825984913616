:root {
  --padding: 2rem;
  --padding-bottom: 9rem;
  --padding-bottom--mobile: 30vh;

  --font: 'Klavika', sans-serif;

  --transition-duration: 300ms;
  --transition: all var(--transition-duration) ease-in-out;
  --loading-transition: all 1s linear;
  --bg-transition: all 0.5s linear;

  --color-smoke: #ffffffee;
  --color-primary: #fff;
  --color-secondary: #000;
  --color-active: yellow;
  --color-selection: #00000070;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: var(--font);
  font-size: 16px;
  color: var(--color-primary);
  background: var(--color-secondary);
  line-height: 1.2;
  overflow: hidden;
}

h1 {
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.1em;
  font-size: 3rem;

  @include media-tablet {
    font-size: 2.5rem;
  }

  @include media-mobile {
    font-size: 2rem;
  }
}

p {
  text-shadow: 0px 0px 4px var(--color-secondary);
}

button {
  font-family: var(--font);
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  transition: var(--transition);
}

.main--hidden {
  opacity: 0;
}

::selection {
  background: var(--color-selection);
  color: var(--color-primary);
}

.hideable {
  transition: var(--transition);
}

.hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

.shown {
  opacity: 1 !important;
  visibility: visible !important;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}
