.loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: var(--loading-transition);
}

.loading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-style: italic;
  animation: loadingContent var(--transition-duration)
    cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &::before {
    content: '';
    position: absolute;
    box-shadow: -2.5px -15px 80px 35px var(--color-smoke);
  }
}

.loading__img {
  user-select: none;
  position: relative;
  z-index: 1;
  animation: loadingImg infinite 3s var(--transition-duration) ease-in-out;
}

.loading__line {
  display: flex;
  height: 32px;
  transition: var(--transition);
}

.loading__title {
  opacity: 0;
  transform: scale(0);
  display: block;
  transition: var(--transition);
}

.loading__title--shown {
  opacity: 1;
  transform: scale(1);
}

.loading__suffix {
  opacity: 1;
}

.loading__suffix--hidden {
  opacity: 0;
  width: 0;
}

.loading__message {
  opacity: 0;
  width: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  transition: var(--transition);
}

.loading__message--shown {
  opacity: 1;
  width: 122px;
  left: -4px;
}

@keyframes loadingContent {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes loadingImg {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
