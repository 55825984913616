@font-face {
  font-family: 'Klavika';
  src: url('../assets/fonts/Klavika-Bold.eot');
  src: local('Klavika-Bold'),
    url('../assets/fonts/Klavika-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Klavika-Bold.woff2') format('woff2'),
    url('../assets/fonts/Klavika-Bold.woff') format('woff'),
    url('../assets/fonts/Klavika-Bold.ttf') format('truetype'),
    url('../assets/fonts/Klavika-Bold.svg#Klavika-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Klavika';
  src: url('../assets/fonts/Klavika-Regular.eot');
  src: local('Klavika Rg'), local('Klavika-Regular'),
    url('../assets/fonts/Klavika-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Klavika-Regular.woff2') format('woff2'),
    url('../assets/fonts/Klavika-Regular.woff') format('woff'),
    url('../assets/fonts/Klavika-Regular.ttf') format('truetype'),
    url('../assets/fonts/Klavika-Regular.svg#Klavika-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Klavika';
  src: url('../assets/fonts/Klavika-Light.eot');
  src: local('Klavika Lt'), local('Klavika-Light'),
    url('../assets/fonts/Klavika-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Klavika-Light.woff2') format('woff2'),
    url('../assets/fonts/Klavika-Light.woff') format('woff'),
    url('../assets/fonts/Klavika-Light.ttf') format('truetype'),
    url('../assets/fonts/Klavika-Light.svg#Klavika-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Klavika';
  src: url('../assets/fonts/Klavika-Medium.eot');
  src: local('Klavika Md Bold'), local('Klavika-Medium'),
    url('../assets/fonts/Klavika-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Klavika-Medium.woff2') format('woff2'),
    url('../assets/fonts/Klavika-Medium.woff') format('woff'),
    url('../assets/fonts/Klavika-Medium.ttf') format('truetype'),
    url('../assets/fonts/Klavika-Medium.svg#Klavika-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
