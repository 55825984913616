aside {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80vw;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: var(--padding);
  padding-bottom: var(--padding-bottom);
  z-index: 0;
  transform: translateX(100%);
  transition: var(--transition);

  p {
    a {
      color: var(--color-primary);
      text-decoration: underline;
      transition: var(--transition);

      &:hover {
        color: var(--color-active);
      }
    }
  }

  @include media-tablet {
    padding-bottom: var(--padding-bottom--mobile);
  }
}

.aside--shown {
  transform: translateX(0);
}

.aside__content {
  margin-top: auto;
  margin-bottom: auto;
}

.social {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;

  @include media-tablet {
    flex-direction: column;
    align-items: flex-end;
  }
}

.social__icon {
  svg {
    transition: var(--transition);
    fill: var(--color-primary);
    width: 1rem;
    height: 1rem;
  }
}

.social__item {
  position: relative;
  text-decoration: none;
  display: flex;
  gap: 0.5rem;
  transition: var(--transition);

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    bottom: -0.5rem;
    left: 0;
    width: 0;
    transition: var(--transition);
    background: var(--color-active);
  }

  &:hover {
    color: var(--color-active);

    .social__icon svg {
      fill: var(--color-active);
    }

    &::after {
      width: 100%;
    }
  }
}
